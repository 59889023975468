<template>
  <svg viewBox="0 0 40 40">
    <path
      d="M13.7782 24.4668C13.4715 24.4668 13.2649 24.4968 13.1582 24.5268V26.4901C13.2849 26.5201 13.4432 26.5285 13.6615 26.5285C14.4599 26.5285 14.9515 26.1251 14.9515 25.4435C14.9515 24.8335 14.5282 24.4668 13.7782 24.4668V24.4668ZM19.5899 24.4868C19.2565 24.4868 19.0399 24.5168 18.9115 24.5468V28.8968C19.0399 28.9268 19.2465 28.9268 19.4332 28.9268C20.7949 28.9368 21.6815 28.1868 21.6815 26.6001C21.6915 25.2168 20.8832 24.4868 19.5899 24.4868V24.4868Z"
    />
    <path
      d="M23.3327 3.33398H9.99935C9.11529 3.33398 8.26745 3.68517 7.64233 4.31029C7.0172 4.93542 6.66602 5.78326 6.66602 6.66732V33.334C6.66602 34.218 7.0172 35.0659 7.64233 35.691C8.26745 36.3161 9.11529 36.6673 9.99935 36.6673H29.9993C30.8834 36.6673 31.7312 36.3161 32.3564 35.691C32.9815 35.0659 33.3327 34.218 33.3327 33.334V13.334L23.3327 3.33398ZM15.8293 26.984C15.3143 27.4673 14.5543 27.684 13.6693 27.684C13.4977 27.6858 13.3262 27.6758 13.156 27.654V30.0307H11.666V23.4707C12.3384 23.3703 13.0179 23.3246 13.6977 23.334C14.626 23.334 15.286 23.5107 15.731 23.8657C16.1543 24.2023 16.441 24.754 16.441 25.404C16.4393 26.0573 16.2227 26.609 15.8293 26.984V26.984ZM22.1743 29.2423C21.4743 29.824 20.4093 30.1007 19.1077 30.1007C18.3277 30.1007 17.776 30.0507 17.401 30.0007V23.4723C18.0737 23.3741 18.7529 23.3279 19.4327 23.334C20.6943 23.334 21.5143 23.5607 22.1543 24.044C22.846 24.5573 23.2793 25.3757 23.2793 26.5507C23.2793 27.8223 22.8143 28.7007 22.1743 29.2423V29.2423ZM28.3327 24.6173H25.7793V26.1357H28.166V27.359H25.7793V30.0323H24.2693V23.384H28.3327V24.6173ZM23.3327 15.0007H21.666V6.66732L29.9993 15.0007H23.3327Z"
    />
  </svg>
</template>

<script>
export default {
  name: "FilePdfIcon",
};
</script>
